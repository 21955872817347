<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <div class="fluid" id="pjcaList">
          <div>
            <div class="pjca-list-dev">
              <v-card elevation="0" class="px-3 py-2" style="width: 100%">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex"
                    style="
                      position: relative;
                      padding: 0 20px;
                      text-align: center;
                      margin-top: 20px;
                    "
                  >
                    <h4
                      class="indigo--text"
                      style="font-weight: bold; margin: auto"
                    >
                      PJCA
                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            x-small
                            text
                            icon
                            color="grey darken-2"
                            @click="getDataFromApi"
                          >
                            <v-icon small>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>refresh page</span>
                      </v-tooltip>
                    </h4>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <v-toolbar
                        class="pjca-list-toolbar-1"
                        flat
                        style="
                          height: 60px;
                          border-radius: 10px 10px 0 0;
                          margin-top: 15px;
                        "
                      >
                        <div
                          class="d-flex align-content-start justify-between"
                          style="width: 100%"
                        >
                          <div
                            style="
                              width: 300px;
                              height: 45px;
                              margin-left: auto;
                            "
                          >
                            <v-text-field
                              v-model="paramAPI.keyword"
                              label="cari dokumen"
                              type="search"
                              outlined
                              dense
                              append-icon="mdi-magnify"
                              style="margin-left: 10px; font-size: 12px"
                              @keyup.enter="searchEnter"
                              :disabled="loading"
                            ></v-text-field>
                          </div>
                        </div>
                      </v-toolbar>
                      <v-toolbar
                        class="pjca-list-toolbar-1"
                        flat
                        style="
                          height: 60px;
                          border-radius: 10px 10px 0 0;
                          margin-top: 10px;
                        "
                      >
                        <div
                          class="d-flex align-content-start"
                          style="width: 100%"
                        >
                          <div style="width: 210px">
                            <v-autocomplete
                              v-model="paramAPI.company_id"
                              label="Company"
                              item-text="name"
                              item-value="id"
                              style="font-size: 12px"
                              return-object
                              dense
                              outlined
                              clearable
                              @change="companyWatcher"
                              :items="dropdown.company"
                              :disabled="isDisableCompanyDropdown"
                            ></v-autocomplete>
                          </div>
                          <div style="width: 210px; margin-left: 15px">
                            <v-autocomplete
                              v-model="paramAPI.department_id"
                              label="Department"
                              item-text="name"
                              item-value="id"
                              style="font-size: 12px"
                              return-object
                              dense
                              outlined
                              clearable
                              @change="departmentWatcher"
                              :items="dropdown.department"
                            ></v-autocomplete>
                          </div>

                          <div style="width: 150px; margin-left: 15px">
                            <v-select
                              label="Status"
                              v-model="paramAPI.status_id"
                              :items="dropdown.status"
                              style="font-size: 12px"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                              clearable
                              outlined
                              @change="statusWatcher"
                            ></v-select>
                          </div>
                          <div style="width: 180px; margin-left: 15px">
                            <v-text-field
                              dense
                              v-model="paramAPI.start_date"
                              label="Dari Tanggal"
                              type="date"
                              name="startdate"
                              style="height: 30px"
                              step="1"
                              outlined
                              clearable
                              @change="startDateWatcher"
                            />
                          </div>
                          <div style="width: 180px; margin-left: 15px">
                            <v-text-field
                              dense
                              v-model="paramAPI.end_date"
                              label="Sampai Tanggal"
                              type="date"
                              name="startdate"
                              style="height: 30px"
                              step="1"
                              outlined
                              clearable
                              @change="endDateWatcher"
                            />
                          </div>
                        </div>
                      </v-toolbar>
                    </div>
                  </v-col>

                  <v-col cols="12" style="width: 100%">
                    <v-data-table
                      v-if="result.length > 0 || !skeleton_loading"
                      mobile-breakpoint="0"
                      fixed-header
                      height="50vh"
                      :headers="headers"
                      style="cursor: pointer"
                      :items="result"
                      :loading="loading"
                      @click:row="rowClick"
                      item-key="id"
                      :options.sync="options"
                      :footer-props="{
                        showFirstLastPage: true,
                        showCurrentPage: true,
                        itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                      }"
                      :server-items-length="totalData"
                      :page="paramAPI.page"
                      @update:page="updatePage"
                      :items-per-page="paramAPI.itemsPerPage"
                      @update:items-per-page="updateItemPerPage"
                    >
                      <!-- <template v-slot:[`item.no`]="{ index }">
                        <div
                          style="
                            font-size: 14px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                        </div>
                      </template> -->
                      <template v-slot:[`item.document_no`]="{ item }">
                        <div
                          :style="`min-width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.document_no }}
                        </div>
                      </template>
                      <template v-slot:[`item.payment_request`]="{ item }">
                        <div
                          :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{
                            item.payment_request != null
                              ? item.payment_request.document_no
                              : ''
                          }}
                        </div>
                      </template>
                      <template v-slot:[`item.employee_name`]="{ item }">
                        <div
                          style="
                            width: 100px;
                            font-size: 11px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.employee_name }}
                        </div>
                      </template>
                      <template v-slot:[`item.company_name`]="{ item }">
                        <div
                          :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.company_name }}
                        </div>
                      </template>
                      <template v-slot:[`item.department_name`]="{ item }">
                        <div
                          :style="`width:150px; font-size:11px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
                        >
                          {{ item.department_name }}
                        </div>
                      </template>
                      <!-- <template v-slot:[`item.updated_at`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.updated_at) }}
                        </div>
                      </template> -->
                      <template v-slot:[`item.status`]="{ item }">
                        <div
                          :style="`text-transform:uppercase; font-size:11px; font-weight:bolder; padding: 0; display: flex; flex-direction: row; color:${statusColor(
                            item.status
                          )};`"
                        >
                          {{ statusName(item.status) }}
                        </div>
                      </template>
                      <template v-slot:[`item.created_at`]="{ item }">
                        <div
                          style="
                            width: 120px;
                            font-size: 11px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ convertDate(item.created_at) }}
                        </div>
                      </template>
                    </v-data-table>
                    <v-skeleton-loader
                      v-else
                      style="width: 100%"
                      type="table-heading, table-tbody, table-tfoot"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>

          <!-- <div>
      <FormPayreq />
    </div> -->
        </div>
      </div>
    </div>
    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      YOU CANNOT ACCESS THIS PAGE
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
// import moment from 'moment'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'pjca',
  components: {
    // FormPayreq: () => import('./Form')
  },
  data: () => ({
    editDialog: false,
    settingDialog: false,
    config: require('../../../services/config.json')[env],
    proc: buildType.apiURL('proc'),
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    build: process.env.VUE_APP_BUILD_TYPE,
    permission: null,
    appLevel: null,
    userLevel: null,
    isLoadingPage: true,
    isDisableCompanyDropdown: false,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'id',
      sortType: 'ASC',
      company_id: null,
      department_id: null,
      employee_id: null,
      mode: 1,
      status_id: null,
      documentType: 1,
      start_date: null,
      end_date: null
    },
    totalData: 0,
    isEdit: false,

    options: {},
    actionValue: 0,
    result: [],
    loading: false,
    dropdown: {
      company: [],
      department: [],
      status: []
    },
    skeleton_loading: true
  }),

  async mounted() {
    this.appLevel = this.config.application.ecatalogue.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    this.getDataMenu()
    // this.getDataFromApi()
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters(['getUserProfile', 'getPjcaPageQuery']),
    headers() {
      const arrHeaders = [
        // {
        //   text: 'No',
        //   value: 'no',
        //   align: 'left',
        //   sortable: false
        // },
        {
          text: 'Status',
          value: 'status',
          sortable: false
        },
        {
          text: 'No. Dokumen',
          value: 'document_no',
          align: 'left',
          sortable: false
        },
        {
          text: 'No. Payreq',
          value: 'payment_request',
          align: 'left',
          sortable: false
        },
        {
          text: 'PIC',
          value: 'employee_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Perusahaan',
          value: 'company_name',
          sortable: false
        },
        {
          text: 'Dari Department',
          value: 'department_name',
          sortable: false
        },
        {
          text: 'Dibuat pada',
          value: 'created_at',
          align: 'left',
          sortable: false
        }
      ]
      // if (this.detail.status.id === 0) {
      return arrHeaders
      // }
    }
  },
  watch: {},
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),
    ...mapMutations(['setPjcaPageQuery']),
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      console.log('Path>>>>>', routeModule)
      await this.getMenuId({
        keyword: this.paramAPI.keyword,
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id ===
                  this.config.application.ecatalogue.applicationId ||
                application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              if (
                this.getUserProfile.level.find(
                  ({ id }) => id == this.appLevel.administrator
                ) !== undefined
              ) {
                this.resetPermission({
                  data: {
                    read_perm: 1,
                    create_perm: 1,
                    update_perm: 1,
                    delete_perm: 1
                  }
                })
              } else {
                this.checkMenuAccess([menuId, this.userLevel.id])
              }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    async initDataForLayout() {
      if (this.userLevel.is_support) {
        this.paramAPI.company_id = {
          id: 0,
          name: ''
        }
      } else {
        this.paramAPI.company_id = {
          id: Number(this.getUserProfile.employee.company.plant_id),
          name: this.getUserProfile.employee.company.name
        }
      }

      if (
        this.getUserProfile.level.find(
          ({ id }) => id == this.appLevel.adminMaster
        ) !== undefined
      ) {
        this.paramAPI.mode = ''
      }
      if (this.getPjcaPageQuery !== null) {
        this.paramAPI = this.getPjcaPageQuery
      }
      setTimeout(async () => {
        this.initDropdown()
        await this.getDataFromApi()
      }, 200)
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    searchEnter() {
      this.resetSomeParam()
      this.getDataFromApi()
    },
    async initDropdown() {
      this.getDropdownCompany()
      this.dropdownDepartment()
      this.dropdownStatus()
    },
    companyWatcher(v) {
      this.resetSomeParam()
      this.getDataFromApi()

      if (v != null) {
        this.dropdownDepartment()
      } else {
        this.dropdown.department = []
      }
    },
    departmentWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    },
    async statusWatcher(v) {
      this.paramAPI.status_id = v
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi()
      }, 100)
    },
    async startDateWatcher(v) {
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi()
      }, 100)
    },
    async endDateWatcher(v) {
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi()
      }, 100)
    },
    dropdownStatus() {
      this.dropdown.status = [
        {
          id: 0,
          name: 'Draft'
        },
        {
          id: 1,
          name: 'Waiting Approval'
        },
        {
          id: 2,
          name: 'In Progress'
        },
        {
          id: 3,
          name: 'Done'
        },
        {
          id: 4,
          name: 'Cancel'
        },
        {
          id: 5,
          name: 'Reject'
        }
      ]
    },
    async getDropdownCompany() {
      if (this.userLevel !== null) {
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company =
              this.userLevel.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
      // else {
      //   this.dropdownCompanyApi()
      // }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = this.userLevel.is_support
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async dropdownDepartment() {
      let url = `${this.hrsApi}master/universal/department/dropdown`
      if (this.paramAPI.company_id != null) {
        url = url + `?filter=[{"company_id":${this.paramAPI.company_id.id}}]`
      }
      await axios
        .get(url)
        .then((res) => {
          console.log('department>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    async getDataFromApi() {
      this.loading = true

      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = Number(data.total_record)
            return (this.result = data.data)
          }
          return (this.result = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.skeleton_loading = false
      this.loading = false
    },
    async initDataTable() {
      console.log(this.paramAPI)

      let url = `${this.proc}pjca/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&order_by=${this.paramAPI.sortBy}&order_type=${this.paramAPI.sortType}`

      if (this.paramAPI.status_id !== null) {
        url = url + `&status=${this.paramAPI.status_id}`
        // Object.assign(querySetting, { st: `${this.paramAPI.status_id}` })
      }
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id.id}`
        // Object.assign(querySetting, { cp: `${this.paramAPI.company_id.id}` })
      }
      if (this.paramAPI.department_id !== null) {
        url = url + `&department_id=${this.paramAPI.department_id.id}`
      }
      if (this.paramAPI.start_date !== null) {
        url = url + `&start_date=${this.paramAPI.start_date}`
      }
      if (this.paramAPI.end_date !== null) {
        url = url + `&end_date=${this.paramAPI.end_date}`
      }
      this.setPjcaPageQuery(this.paramAPI)

      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log('list payreq >>>>', res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },

    rowClick(pValue, pSlot) {
      // const arrItem = {
      //   list: this.result
      //   // index: pSlot.index
      // }
      // this.$store.commit('setFpbList', arrItem)
      setTimeout(() => {
        this.$router.push(
          `/e-catalogue/pjca/detail/${pValue.id}?idx=${pSlot.index}`
        )
      }, 200)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    statusName(val) {
      const arrType = ['DRAFT', 'WAITING APPROVAL', 'DONE', 'CANCEL', 'REJECT']
      return arrType[val]
    },
    statusColor(val) {
      const arrType = ['grey', 'orange', 'green', 'red', 'red']
      return arrType[val]
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    }
  }
}
</script>
<style lang="scss">
#pjcaList {
  position: relative;
  .pjca-list-dev {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .pjca-list-toolbar-1 {
      .pjca-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #pjcaList {
    .pjca-list-dev {
      .pjca-list-toolbar-1 {
        .pjca-list-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
